.header {
  position: fixed;
  top: 0;
  z-index: 999999;
  width: 100%;
}

.title-section {
  padding-top: 10rem;
}

button.btn-login, button.btn-register {
  width: 310.311px;
  height: 48px;
  border-radius: 36px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

button.btn-login {
  border: 1px solid var(--e-42-d-79, #E42D79);
  background: rgba(22, 20, 21, 0.15);
  backdrop-filter: blur(10px);
  color: var(--e-42-d-79, #E42D79);
}

button.btn-register{
  border: 1px solid var(--e-42-d-79, #E42D79);
  background: var(--e-42-d-79, #E42D79);
  backdrop-filter: blur(3px);
  color: #FFF;
}

.button-head {
  display: flex;
  gap: 30px;
}

p{
  margin: 0px;
  padding: 0px;
}

img {
  width: 100%;
}

.center-container {
  padding: 0px;
  max-width: 1440px;
  margin: auto;
  width: 100%;
}


.title-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.title-text h1 {
  color: #FFF;
  font-size: 43.546px;
  font-weight: 700;
  margin: 0px;
  text-align: center;
}

.title-text span {
  background: linear-gradient(119deg, #C92C8C 6.81%, #E42D79 33.58%, #FBE320 90.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 10.724px 10.724px rgba(0, 0, 0, 0.50);
  font-size: 87.093px;
  font-weight: 700;
}

.title-text p {
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  margin: 0px;
}

.title-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.title-banner img {
  width: 515px;
}

.title-logo {
  margin-bottom: 60px;
  max-width: 476px;
}

.title-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.title-menu img {
  width: 360px;
}

.promotion-text p{
  margin: 0px;
}

.promotion-text span{
  color: var(--fbe-320, #FBE320);
}

.promotion-text {
  background-image: url(../Images/new-center/bg-text.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 50%;
  height: 423px;
  flex-shrink: 0;
  padding: 40px 46px;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 35px;
}

.promotion-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 28px;
}

.splide__pagination {
  bottom: -2em !important;
  gap: 10px !important;
}

.splide__pagination__page.is-active {
  background: #FEFC53 !important;
  border-radius: 20px;
  width: 38px !important;
  opacity: 1 !important;
}

.splide__pagination__page {
  background: #FEFC53 !important;
  width: 12px !important;
  height: 12px !important;
  opacity: 0.5 !important;
}


.category .category-slot, .category .category-lotto, .category .category-casino, .category .category-sport{
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 87.108px;
  height: 87.108px;
}

.category.active .category-slot, .category.active .category-lotto, .category.active .category-casino, .category.active .category-sport{
  width: 101.217px;
  height: 101.217px;
}

.category-slot {
  background-image: url(../Images/new-center/slot.png);

}

.category-lotto{
  background-image: url(../Images/new-center/lotto.png);
}

.category-casino{
  background-image: url(../Images/new-center/casino.png);
}

.category-sport{
  background-image: url(../Images/new-center/sport.png);
}

.category {
  background-image: url(../Images/new-center/category-bg.svg);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 135.115px;
  height: 135.115px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
}

.category.active {
  width: 157px;
  height: 157px;
  margin: auto;
}

.category span {
  background: linear-gradient(119deg, #C92C8C 6.81%, #E42D79 33.58%, #FBE320 90.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-size: 18.646px;
  font-weight: 700;
  line-height: normal;
}

.category.active span {
  font-size: 20.82px;
}

.games-category {
  display: flex;
  flex-direction: column-reverse;
  gap: 40px;
}

.games-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.games-box {
  background-image: url(../Images/new-center/box-bg.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 0px 46px;
}

.question-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.question-text {
  background-image: url(../Images/new-center/bg-text.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 6rem 2rem 2rem 2rem;
  width: 75%;
  margin: auto;
}
.question-text-bg img {
  transform: translate(20px, 85px);
}
.group-question {
  transform: translate(0px, -100px);
}
.question-text p {
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  
}

.footer-section {
  width: 100%;
  height: 507px;
  background: rgba(22, 20, 21, 0.50);
  backdrop-filter: blur(36.923072814941406px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.icon-bank img {
  width: 74.856px;
  height: 74.856px;
}

.icon-bank {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.footerlogo {
  width: 497.887px;
  margin: auto;
}

.footer-text {
  width: 900px;
  margin: 70px auto;
  text-align: center;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 205.5%;
}

.title-section, .promotion-section, .games-section, .question-section {
  padding: 0px 78px;
}

.custom-slider .splide__pagination{
  display: none;
}

.custom-slider{
  display: none;
}

.header-group img {
  width: 312px;
}

.header-group {
  background-image: url(../Images/new-center/bg-head.png);
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 40px;
  max-width: 1260px;
  margin: auto;
}

.button-head-mb{
  display: none;
}

.icon-line-btn {
  width: 100px;
  height: 100px;
  position: fixed;
  bottom: 0;
  z-index: 999999;
  right: 10px;
}

@media screen and (max-width: 1400px) {
  .header-group {
    max-width: 1100px;
  }
}
@media screen and (max-width: 1099px) {

  .title-section, .promotion-section, .games-section, .question-section {
    padding: 0px;
  }

  .title-box, .promotion-box, .games-box, .question-section  {
    flex-direction: column;
  }

  .games-list {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0px 10px;
    gap: 20px;
  }

  .games-category {
    flex-direction: row;
    gap: 20px;
  }

  .promotion-text {
    width: 90%;
    height: 380px;
    margin-top: 1rem;
  }
  .promotion-text-box {
    overflow: scroll;
    height: 380px;
  }
  .center-container {
    padding: 0px;
  }

  .promotion-section, .games-section, .question-section, .title-box {
    padding: 0px 24px;
  }

  .games-box {
    padding: 0px;
  }

  .games-box {
    background-image: url(../Images/new-center/box-bg-mb.png);
    padding: 1rem 0rem;
    height: 400px;
    justify-content: flex-start;
  }

  .title-menu {
    overflow: auto;
    justify-content: space-around;
  }

  .splide__pagination__page {
    width: 10px !important;
    height: 10px !important;
  }

  .games-section, .promotion-section {
    margin-top: 25px;
  }

  .promotion-title {
    margin-bottom: 1rem;
  }

  .question-banner {
    margin-top: -9rem;
    z-index: 9;
  }

  .icon-bank img {
    width: 32.164px;
    height: 32.164px;
  }

  .footer-text {
    width: 65%;
    margin: 30px auto;
    font-size: 12px;
    font-weight: 300;
    line-height: 205.5%;
  }

  .footerlogo {
    width: 203px;
  }

  .footer-section {
    height: 259px;
  }

  .title-logo {
    margin-bottom: 20px;
  }

  button.btn-login, button.btn-register {
    width: 183px;
    height: 42px;
    font-size: 16px;
  }

  .button-head {
    gap: 16px;
    margin-top: 32px;
    margin-left: 0px;
    justify-content: center;
    align-items: center;
  }

  .title-section {
    padding-top: 7rem;
  }
  .custom-slider{
    display: block;
  }

  .title-menu{
    display: none;
  }

  .header-group {
    background-image: url(../Images/new-center/bg-head-mb.png);
    justify-content: center;
    padding: 10px 40px;
    max-width: 300px;
  }

  .button-head{
    display: none;
  }

  .header-group img {
    width: 181.561px;
  }

  .button-head-mb{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
  }

  .title-text h1 {
    font-size: 38px;
  }

}

@media screen and (max-width: 620px) {

  .category {
    width: 71.409px;
    height: 71.409px;
  }
  .category.active {
    width: 86.524px;
    height: 86.524px;
  }

  .category .category-slot, .category .category-lotto, .category .category-casino, .category .category-sport {
    width: 46.037px;
    height: 46.037px;
  }

  .category.active .category-slot, .category.active .category-lotto, .category.active .category-casino, .category.active .category-sport {
    width: 55.782px;
    height: 55.782px;
  }
  .category span {
    font-size: 9.469px;
  }
  .category.active span {
    font-size: 11.941px;
  }

  .title-logo {
    width: 233.07px;
    height: 41.73px;
  }

  .title-text h1 {
    font-size: 22.261px;
  }

  .title-text span {
    font-size: 38.224px;
  }

  .title-text p {
    font-size: 12px;
  }

  .title-banner img {
    width: 326.704px;
  }

  .title-menu img {
    width: 271.829px;
  }

  .promotion-text {
    width: 90%;
    font-size: 12px;
    padding: 20px;
  }

  .icon-games {
    width: 109.961px;
    height: 118.762px;
  }

  .question-text p {
    font-size: 14px;
  }

  .question-text {
    background-image: url(../Images/new-center/box-bg-mb.png);
    padding: 5rem 32px 3rem 30px;
  }

  .question-text-bg img {
    transform: translate(8px, 70px);
    width: 95%;
  }

  .group-question {
    margin-top: 2rem;
  }

  .footer-text {
    width: 80%;
  }

  .icon-line-btn {
    width: 60px;
    height: 60px;
    right: 5px;
}
}